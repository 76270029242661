import { createStore } from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import modal from './modules/modal';
import user from './modules/user';
import games from './modules/games';
import carousels from './modules/carousels';

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    modal,
    user,
    games,
    carousels,
  },
});
