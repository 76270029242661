import MarketingSlotItem from './MarketingSlotItem';

class MarketingSlot {
  constructor(slot) {
    const currentDate = new Date();

    this.name = slot.name;
    this.items = [];
    slot.items.forEach((item) => {
      if (!item.active) return;

      const isDateRangeSet = Boolean(item.startDate && item.endDate);

      const shouldShowSlot = isDateRangeSet
        ? new Date(item.startDate) < currentDate && currentDate < new Date(item.endDate)
        : true;

      if (!shouldShowSlot) return;

      this.items.push(new MarketingSlotItem(item));
    });
  }
}

export default MarketingSlot;
