import { each, map } from 'lodash-es';
import { gamesApi, gravityApi, carouselsApi } from '@/api';
import { localStorage, logger, MESSAGE_SEVERITY_LEVEL, trackMessageWithContext } from '@/utility';
import integrator from '@/services/integrator';
import rootTypes from '@/store/types';

import Game from '@/models/Game';
import Provider from '@/models/Provider';
import Group from '@/models/Group';
import User from '@/models/User';
import constants from '@/utility/constants';
import types from './types';
import carouselsTypes from '../carousels/types';

const parseGroups = ({
  defaultGroups,
  configuredGroups,
  translations,
  liveCasinoGroupsActive,
  isLive,
}) => {
  const parsedDefaults = map(defaultGroups, (group) => ({
    id: group.id,
    title: translations[group.titleKey],
    titleKey: group.titleKey,
    icon: group.icon,
  }));

  if (isLive && !liveCasinoGroupsActive) {
    return map([...parsedDefaults], (group) => new Group(group));
  }

  return map([...parsedDefaults, ...configuredGroups], (group) => new Group(group));
};

const setGamesIfFavoritesSelected = (id, formattedGames, commit) => {
  const isFavoritesGroupSelected = id === constants.GROUP_IDS.FAVORITES;
  if (isFavoritesGroupSelected) {
    commit(types.SET_GAMES, formattedGames);
  }
};

const syncFavoriteGamesIds = (id, favoriteGamesIds) => {
  const isGameMarkedFavorite = favoriteGamesIds.includes(id);

  if (isGameMarkedFavorite) favoriteGamesIds.splice(favoriteGamesIds.indexOf(id), 1);
  else favoriteGamesIds.push(id);

  return favoriteGamesIds;
};

const MAX_BOOTSTRAP_RETRIES = 5;
let numOfBootstrapRetries = 0;

export default {
  async loadBootstrap({ commit, dispatch, state, rootState }) {
    if (numOfBootstrapRetries === MAX_BOOTSTRAP_RETRIES) {
      commit(rootTypes.SET_BOOTSTRAP_FAILED);
      return;
    }

    const { data, isError } = await gamesApi.loadBootstrap();

    if (isError) {
      logger.error('Failed to load bootstrap');
      numOfBootstrapRetries += 1;
      await dispatch('loadBootstrap');
      return;
    }

    const providers = Object.values(data.providers).map((provider) => new Provider(provider));

    if (rootState.applicationSettings.groupSubgroupIconsActive) {
      const { containerUrl } = data.resources;
      const iconMap = {
        all: `${containerUrl}/all_games_group_icon`,
        pop: `${containerUrl}/popular_group_icon`,
        fav: `${containerUrl}/favorites_group_icon`,
      };

      state.groups = state.groups.map((group) => ({
        ...group,
        icon: {
          base: `${iconMap[group.id]}.png`,
          avif: `${iconMap[group.id]}.avif`,
          webp: `${iconMap[group.id]}.webp`,
        },
      }));
    }

    const groups = parseGroups({
      defaultGroups: state.groups,
      configuredGroups: data.groups,
      translations: rootState.translations,
      liveCasinoGroupsActive: rootState.applicationSettings.liveCasinoGroupsActive,
      isLive: rootState.isLive,
    });
    const promotedGames = data.promotedGames.map((game) => new Game(game));
    const popularGames = data.popularGames.map((game) => new Game(game));

    commit(types.SET_GROUPS, groups);

    const isPopularEmpty = !popularGames.length;
    if (isPopularEmpty) {
      commit(types.REMOVE_POPULAR_GROUP);
    }

    commit(types.SET_POPULAR_GAMES, popularGames);
    commit(types.SET_PROMOTED_GAMES, promotedGames);
    commit(types.SET_PROVIDERS, providers);
  },

  handleGamesSetting({ commit, getters }, { games, pagination }) {
    const {
      getSelectedGroup: selectedGroup,
      getGames: storeGames,
      getPagination: storePagination,
      getRecommendedGames: recommendedGames,
      isSearching,
      isVaixActive,
      isUserLoggedIn,
    } = getters;

    const isLoadingNextPage = pagination.page > 1 && storePagination.total === pagination.total;
    const isDefaultGroup = selectedGroup.id === constants.GROUP_IDS.ALL;
    const shouldRemoveRecommendedGames =
      isVaixActive && isUserLoggedIn && isDefaultGroup && !isSearching && recommendedGames.length;

    let formattedGames = games.map((game) => new Game(game));

    if (isLoadingNextPage) {
      formattedGames = [...storeGames, ...formattedGames];
    } else if (shouldRemoveRecommendedGames) {
      formattedGames = formattedGames.slice(recommendedGames.length);
    }

    commit(types.SET_GAMES, formattedGames);
  },

  async loadGames({ dispatch, commit, getters }, { page, shouldClearCache } = {}) {
    const isLoadingDone = await dispatch('handleDefaultGroupsLoading');
    if (isLoadingDone) {
      return;
    }

    const params = { ...getters.getGamesRequestParams, shouldClearCache };
    if (page) params.page = page;

    const { data, isError } = await gamesApi.loadGames(params);

    if (isError) {
      return;
    }

    dispatch('handleGamesSetting', data);

    commit(types.SET_PAGINATION, data.pagination);
    commit(types.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);
  },

  async loadGame({ commit, getters }, gameId) {
    const { data: game, isError } = await gamesApi.loadGame(gameId);

    const { translations, isMobileDevice } = getters;

    if (!game || isError) {
      commit(rootTypes.SHOW_NOTIFICATION, {
        text: translations.noGameFoundForId,
      });

      return null;
    }

    return new Game(game, isMobileDevice);
  },

  async loadFavoriteGames({ commit, getters }) {
    const { data: favoriteGames, isError } = await gamesApi.loadFavoriteGames(getters.getUserToken);

    if (!favoriteGames || isError) return;

    const isGameFavorite = true;
    const formattedGames = favoriteGames.map((game) => new Game(game, isGameFavorite));

    setGamesIfFavoritesSelected(getters.getFilters?.group.id, formattedGames, commit);

    commit(types.SET_FAVORITE_GAMES, formattedGames);
    commit(types.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);
  },

  async loadRecentlyPlayedGames({ commit, getters }) {
    const { playerUuid } = { ...getters.getGamesRequestParams };
    const userToken = getters.getUserToken;
    const user = getters.getUser;

    const { data: recentlyPlayedGames, isError } = await carouselsApi.loadRecentlyPlayedGames(
      playerUuid,
      userToken,
    );

    if (!recentlyPlayedGames || isError) return;

    if (
      Array.isArray(recentlyPlayedGames) &&
      !recentlyPlayedGames.length &&
      getters.isDebugErrorTrackerActiveForUser
    ) {
      trackMessageWithContext(
        `Empty games array for carousel ${constants.CAROUSELS.RECENTLY_PLAYED}`,
        MESSAGE_SEVERITY_LEVEL.debug,
        user,
      );
    }

    const swimLane = getters.getSwimLaneByCarouselType(constants.CAROUSELS.RECENTLY_PLAYED);
    const formattedGames = recentlyPlayedGames.map((game) => new Game({ ...game, swimLane }));

    commit(types.SET_RECENTLY_PLAYED_GAMES, formattedGames);
  },

  loadFavoriteGamesForUnauthorized({ commit, getters }) {
    const favoritesLocalStorageKey = getters.isLive
      ? `liveCasinoFavoriteGames-${getters.getTenant.uuid}`
      : `casinoFavoriteGames-${getters.getTenant.uuid}`;

    const favoriteGames = localStorage.getItem(favoritesLocalStorageKey);

    if (!favoriteGames) return;

    const isGameFavorite = true;
    const formattedGames = favoriteGames.map((game) => new Game(game, isGameFavorite));

    setGamesIfFavoritesSelected(getters.getFilters?.group.id, formattedGames, commit);

    commit(types.SET_FAVORITE_GAMES, formattedGames);
    commit(types.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);
  },

  async toggleFavoriteGame({ dispatch, commit, getters }, id) {
    if (!getters.isUserLoggedIn) {
      dispatch('setFavoriteGamesForUnauthorized', id);
      return;
    }

    const favoriteGamesIds = syncFavoriteGamesIds(id, getters.getFavoriteGamesIds);
    const favoriteGamesKey = getters.isLive ? 'casinoLive.favorites' : 'casino.favorites';

    dispatch('updateFavoriteGamesList', id);
    commit(types.UPDATE_GAMES_FAVORITE_PROPERTY, favoriteGamesIds);

    await gravityApi.updateUserFavoriteGames({
      userToken: getters.getUserToken,
      favoriteGamesIds,
      favoriteGamesKey,
    });
  },

  setFavoriteGamesForUnauthorized({ dispatch, commit, getters }, id) {
    dispatch('updateFavoriteGamesList', id);
    commit(types.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);

    const favoritesLocalStorageKey = getters.isLive
      ? `liveCasinoFavoriteGames-${getters.getTenant.uuid}`
      : `casinoFavoriteGames-${getters.getTenant.uuid}`;

    localStorage.setItem(favoritesLocalStorageKey, getters.getFavoriteGames);
  },

  updateFavoriteGamesList({ commit, state, getters }, id) {
    const {
      getFavoriteGameIndex,
      getGameById,
      getPromotedGameById,
      getRecommendedGameById,
      getTournamentGameById,
      getTop10GameById,
      getTrendingThisWeekGameById,
      getBecauseYouPlayedGameById,
      getRecentlyPlayedGameById,
      getNewReleasesGameById,
      getSupplierOfTheMonthGameById,
      getOurHottestTitlesGameById,
    } = getters;

    const favoriteGameIndex = getFavoriteGameIndex(id);
    const isGameInFavorites = favoriteGameIndex !== -1;

    if (isGameInFavorites) {
      commit(types.REMOVE_FAVORITE_GAME, favoriteGameIndex);
    } else {
      const game =
        getGameById(id) ||
        getPromotedGameById(id) ||
        getRecommendedGameById(id) ||
        getTournamentGameById(id) ||
        getTop10GameById(id) ||
        getTrendingThisWeekGameById(id) ||
        getBecauseYouPlayedGameById(id) ||
        getRecentlyPlayedGameById(id) ||
        getNewReleasesGameById(id) ||
        getSupplierOfTheMonthGameById(id) ||
        getOurHottestTitlesGameById(id);

      if (game) {
        commit(types.ADD_FAVORITE_GAME, game);
      } else {
        // opened game is not loaded initially
        commit(types.ADD_FAVORITE_GAME, state.activeGame);
      }
    }
  },

  async filterGames({ commit, dispatch }, filters) {
    commit(types.SET_PAGINATION_FIRST_PAGE);

    await dispatch('setFilterParameters', filters);

    await dispatch('loadGames');
  },

  setFilterParameters({ commit }, filters) {
    each(filters, (filter) => {
      const shouldFilterByGroup = filter.type === 'group';
      if (shouldFilterByGroup) commit(types.RESET_SUBGROUP);

      commit(types.SET_FILTER_PARAMETER, { filter: filter.type, value: filter.value });
    });
  },

  handleDefaultGroupsLoading({ commit, getters, state }) {
    if (getters.isSearching) return false;

    const { getFilters: filters } = getters;
    const POPULAR_GROUP_ID = constants.GROUP_IDS.POPULAR;
    const FAVORITES_GROUP_ID = constants.GROUP_IDS.FAVORITES;

    const isPopularFilterClicked = filters.group.id === POPULAR_GROUP_ID;
    const isFavoritesFilterClicked = filters.group.id === FAVORITES_GROUP_ID;

    if (isPopularFilterClicked) commit(types.SET_GAMES, state.popularGames);
    if (isFavoritesFilterClicked) commit(types.SET_GAMES, state.favoriteGames);

    return isPopularFilterClicked || isFavoritesFilterClicked;
  },

  async loadNextPage({ commit, dispatch }) {
    commit(types.SET_PAGINATION_NEXT_PAGE);
    await dispatch('loadGames');
  },

  setActiveGame({ commit }, game) {
    commit(types.SET_ACTIVE_GAME, game);
  },

  setLastActiveGame({ commit }, game) {
    commit(types.SET_LAST_ACTIVE_GAME, game);
  },

  async setActiveGamePlayable({ commit, getters }, { game, isDemo = false }) {
    let isActiveGameSet = false;
    const {
      isUserLoggedIn,
      getUser: user,
      getTenant: tenant,
      getReferrerUrl: referrerUrl,
      getProviders: providers,
      getLanguage: language,
      getFavoriteGamesIds: favoritesIds,
      translations,
    } = getters;

    if (!game?.demo && !isUserLoggedIn) {
      User.loginRequired();
      return isActiveGameSet;
    }

    const shouldOpenDemo = isDemo || !isUserLoggedIn;

    game.setProvider(providers);
    game.setResolving(true);
    const gameUrl = await game.getUrl({
      demo: shouldOpenDemo,
      user,
      tenant,
      referrerUrl,
      language,
    });
    game.setResolving(false);

    if (gameUrl?.isError) {
      commit(rootTypes.SHOW_NOTIFICATION, {
        text: translations[gameUrl.translationKey] || translations.gameOpeningError,
      });
      return isActiveGameSet;
    }

    game.setUrl(gameUrl);

    if (favoritesIds.includes(game.id)) {
      game.setFavorite(true);
    }

    commit(types.SET_ACTIVE_GAME, game);
    commit(types.SET_DEMO_MODE, shouldOpenDemo);
    isActiveGameSet = true;
    return isActiveGameSet;
  },

  destroyActiveGameUrl({ commit }) {
    commit(types.REMOVE_ACTIVE_GAME_URL);
  },

  async openGame({ dispatch, getters }, payload) {
    const isGameSet = await dispatch('setActiveGamePlayable', payload);

    if (isGameSet) {
      const game = {
        ...getters.getActiveGame,
        isVaixEvent: getters.isVaixActive && getters.isAllGroupSelected,
        isLive: getters.isLive,
      };

      dispatch('openGameModal');
      integrator.notifyGameEvent('open', game);
    }

    return isGameSet;
  },

  async openGameRedirect({ dispatch, getters }, payload) {
    const isGameSet = await dispatch('setActiveGamePlayable', payload);

    if (isGameSet) {
      const game = {
        ...getters.getActiveGame,
        isVaixEvent: getters.isVaixActive && getters.isAllGroupSelected,
        isLive: getters.isLive,
      };

      dispatch('closeDescriptionModal');
      integrator.requestGameOpening(game);
    }
  },

  closeGame({ getters, dispatch }) {
    const game = {
      ...getters.getActiveGame,
      isVaixEvent: getters.isVaixEvent,
      isLive: getters.isLive,
    };

    integrator.notifyGameEvent('close', game);
    dispatch('setLastActiveGame', game);
    dispatch('closeGameModal');
  },

  async loadLastPlayedGameProductDisplayId({ getters }) {
    const userToken = getters.getUserToken;
    const { game: productDisplayId, isError } = await carouselsApi.loadLastPlayedGame(userToken);

    if (!productDisplayId || isError) return null;

    return productDisplayId;
  },

  async loadLastPlayedGameAndSetActiveGame({ commit, getters }) {
    const userToken = getters.getUserToken;
    const { game: productDisplayId, isError } = await carouselsApi.loadLastPlayedGame(userToken);
    if (!productDisplayId || isError) {
      return commit(carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE, {
        carouselType: constants.CAROUSELS.BECAUSE_YOU_PLAYED,
        isCarouselActive: false,
      });
    }

    const { game, isError: isLoadLastGameError } =
      await carouselsApi.loadLastGameByProductDisplayId(userToken, productDisplayId);

    if (!game || isLoadLastGameError) return null;

    commit(types.SET_LAST_PLAYED_GAME, game);
    return productDisplayId;
  },

  async loadBecauseYouPlayedGames({ commit, getters, dispatch }, game) {
    const user = getters.getUser;
    const userToken = getters.getUserToken;
    const { playerUuid } = { ...getters.getGamesRequestParams };
    const lastPlayedGameProductId =
      game?.productDisplayId || (await dispatch('loadLastPlayedGameAndSetActiveGame'));
    if (!lastPlayedGameProductId) return;

    const { games, isError } = await carouselsApi.loadBecauseYouPlayedGames(
      playerUuid,
      userToken,
      lastPlayedGameProductId,
    );

    if (!games || isError) return;

    if (Array.isArray(games) && !games.length && getters.isDebugErrorTrackerActiveForUser) {
      trackMessageWithContext(
        `Empty games array for carousel ${constants.CAROUSELS.BECAUSE_YOU_PLAYED}`,
        MESSAGE_SEVERITY_LEVEL.debug,
        user,
      );
    }

    const swimLane = getters.getSwimLaneByCarouselType(constants.CAROUSELS.BECAUSE_YOU_PLAYED);
    const formattedGames = games.map((element) => new Game({ ...element, swimLane }));

    commit(carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE, {
      carouselType: constants.CAROUSELS.BECAUSE_YOU_PLAYED,
      isCarouselActive: true,
    });
    if (game) commit(types.SET_LAST_PLAYED_GAME, game);
    commit(types.SET_BECAUSE_YOU_PLAYED_GAMES, formattedGames);
  },

  notifyGameClickEvent() {
    integrator.notifyGameClickEvent();
  },
};
