<template>
  <transition
    :duration="duration"
    :name="name"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'AppTransition',

  props: {
    name: {
      type: String,
      default: 'fade',
    },
    duration: {
      type: Number,
      default: 150,
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms $ease-out-cubic;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: opacity 200ms $ease-out-quint, transform 250ms $ease-out-quint;
  transform-origin: top;
}

.slide-down-leave-to,
.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-0.5rem);
}

.slide-down-slider-enter-active,
.slide-down-slider-leave-active {
  transition: opacity 250ms $ease-out-quint, transform 300ms $ease-out-quint;
  transform-origin: top;
}

.slide-down-slider-leave-to,
.slide-down-slider-enter-from {
  opacity: 0;
  transform: translateY(-2.5rem);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: opacity 200ms $ease-out-quint, transform 250ms $ease-out-quint;
  transform-origin: bottom;
}

.slide-up-leave-to,
.slide-up-enter-from {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
