// iOS version implies safari version, so iOS12 = safari 12 mobile
const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)][0];
  }
  return null;
};

export default iOSversion();
