import types from './types';

export default {
  state: () => ({
    user: {
      name: 'guest',
      id: '',
      email: null,
      firstName: null,
      lastName: null,
      logged: false,
      balance: 0,
      token: 'demo',
      language: 'en',
      languageCode: 'ENG',
      country: 'BA',
    },
  }),

  getters: {
    getUser: (state) => state.user,
    getUserToken: (state) => state.user.token,
    isUserLoggedIn: (state) => state.user.logged,
  },

  mutations: {
    [types.SET_USER](state, user) {
      state.user = user;
    },
    [types.UPDATE_USER](state, { user, token }) {
      state.user.logged = user.logged;
      state.user.id = user.uuid || user.id || '';
      state.user.email = user.email;
      state.user.firstName = user.firstName;
      state.user.lastName = user.lastName;
      state.user.balance = user.balance;
      state.user.nickname = user.nickname;
      state.user.name = user.name || state.user.defaults.name;
      state.user.token = token || user.token || state.user.defaults.token;
      state.user.profileToken = user.profile?.token || state.user.defaults.token; // legacy, should be removed
      state.user.timezoneOffset = user.profile?.timezoneOffset;
      state.user.oddsFormat = user.profile?.oddsDisplay;
      state.user.country = user.profile?.country;
    },
    [types.SET_USER_BALANCE](state, balance) {
      state.user.balance = balance;
    },
  },

  actions: {
    changeAuthorization({ commit }, payload) {
      commit(types.UPDATE_USER, payload);
    },

    changeBalance({ commit }, balance) {
      commit(types.SET_USER_BALANCE, balance);
    },
  },
};
