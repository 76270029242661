class MarketingSlotItem {
  constructor(slotItem) {
    this.id = slotItem.id;
    this.url = slotItem.url;
    this.imageUrl = slotItem.resource && slotItem.resource.url;
    this.textContent = slotItem.textContent;
    if (slotItem.customData) this.appendAdditionalData(slotItem.customData);
  }

  appendAdditionalData(data) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

export default MarketingSlotItem;
