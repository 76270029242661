import { createApp } from 'vue';
import { initErrorTracker, loadFont, setPalette } from '@/utility';
import { initGTM } from '@/plugins';
import { TopPositionDirective } from '@/directives';
import integrator from '@/services/integrator';
import AppTransition from '@/components/common/AppTransition';
import config from '../config';
import App from './App';
import router from './router';
import store from './store';
import './polyfills';
import '@/styles/main.scss';

const app = createApp(App);

app.component('AppTransition', AppTransition);
app.directive('top-position', TopPositionDirective);

const setupSevenComponents = async () => {
  document.documentElement.dataset.scChannel = 'terminal';
  document.documentElement.dataset.scTheme = 'dark';
  document.documentElement.dataset.scPalette = 'default';

  await Promise.all([
    import('@nsftx/seven-components/style'),
    import('@nsftx/seven-components/styles/components/terminal'),
    import('@nsftx/seven-components/palettes/default'),
  ]);
};

const setupApp = async () => {
  await store.dispatch('loadApplicationSettings');
  const { palette, font, GTMCode } = store.state.applicationSettings;

  if (GTMCode) initGTM(app, GTMCode);
  setPalette(palette);
  loadFont(font);
};

const startApp = async () => {
  if (config.errorTracker.active) initErrorTracker(app, router);

  await setupApp();

  if (config.integration.active) {
    const message = await integrator.init(config.integration);

    store.dispatch('setInitialAppData', message);
  } else {
    // for app to work in non integration mode
    store.dispatch('setInitialAppData');
  }

  await store.dispatch('loadInitialData');

  if (store.getters.isTerminalChannelActive) {
    await setupSevenComponents();
  }

  app.use(router).use(store).mount('#app');

  if (config.useMemoryHistory) {
    const path = window.location.href.replace(window.location.origin, '');

    await router.push({ path, replace: true });
  }
};

startApp();
