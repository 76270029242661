import constants from '@/utility/constants';

export default {
  carousels: [],
  carouselTypeSwimLane: {
    tournament: constants.VAIX.SWIM_LANE_TOURNAMENT,
    trending: constants.VAIX.SWIM_LANE_TRENDING_THIS_WEEK,
    top_n: constants.VAIX.SWIM_LANE_TOP_N,
    recommended_for_you: constants.VAIX.SWIM_LANE_RECOMMENDED,
    new_releases: constants.VAIX.SWIM_LANE_NEW_RELEASES,
    recently_played: constants.VAIX.SWIM_LANE_RECENTLY_PLAYED,
    because_you_played: constants.VAIX.SWIM_LANE_BECAUSE_YOU_PLAYED,
    supplier_of_the_month: constants.VAIX.SWIM_LANE_SUPPLIER_OF_THE_MONTH,
    hottest_games: constants.VAIX.SWIM_LANE_OUR_HOTTEST_TITLES,
  },
  breakpoints: {
    common: {
      1: {
        slidesPerView: 2.25,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
      768: {
        slidesPerView: 3.15,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
      1024: {
        slidesPerView: 4.15,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
      1280: {
        slidesPerView: 5.15,
        slidesPerGroup: 3,
        slidesPerColumn: 1,
      },
    },
    [constants.CAROUSELS.TOP_N]: {
      1: {
        slidesPerView: 1.7,
        slidesPerGroup: 1,
        slidesPerColumn: 1,
      },
      768: {
        slidesPerView: 2.19,
        slidesPerGroup: 1,
        slidesPerColumn: 1,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        slidesPerColumn: 1,
      },
      1280: {
        slidesPerView: 3.75,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
    },
  },
};
