class Group {
  constructor(group) {
    this.id = group.id;
    this.name = group.title;
    this.titleKey = group.titleKey;
    this.games = group.games;
    this.icon = group.icon;

    if (group.subgroups) {
      this.subgroups = group.subgroups.map((subgroup) => new Group(subgroup));
    }
  }
}

export default Group;
