import constants from '@/utility/constants';

export default {
  games: [],
  popularGames: [],
  promotedGames: [],
  favoriteGames: [],
  recentlyPlayedGames: [],
  recommendedGames: [],
  tournamentGames: [],
  top10Games: [],
  trendingThisWeekGames: [],
  newReleasesGames: [],
  becauseYouPlayedGames: [],
  supplierOfTheMonthGames: [],
  ourHottestTitlesGames: [],
  lastActiveGame: null,
  lastPlayedGame: null,
  activeGame: null,
  groups: [
    { id: constants.GROUP_IDS.ALL, name: 'All', titleKey: 'allLabel' },
    { id: constants.GROUP_IDS.POPULAR, name: 'Popular', titleKey: 'popularLabel' },
    { id: constants.GROUP_IDS.FAVORITES, name: 'Favorites', titleKey: 'favoritesLabel' },
  ],
  pagination: {
    page: 1,
    pages: 12,
    size: 40,
    total: 1,
  },
  filters: {
    isNew: null,
    search: '',
    group: { id: constants.GROUP_IDS.ALL, name: 'All' },
    subgroup: {},
  },
};
