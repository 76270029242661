import { capitalize } from 'lodash-es';
import { eventBus, logger, prettyPrintJson } from '@/utility';
import constants from '@/utility/constants';

let instance = null;
let initializationResolve = null;

export default {
  init() {
    this.setInstance();

    this.setListener();

    return new Promise((resolve) => {
      initializationResolve = resolve;

      // Notify basic setup is done
      logger.info('Sending Slave.Init');
      instance.sendMessage({
        action: 'Slave.Init',
      });
    });
  },

  setInstance() {
    instance = {
      sendMessage: (data) => {
        const message = typeof data !== 'string' ? JSON.stringify(data) : data;

        try {
          window.webkit.messageHandlers.WebAppListener.postMessage(message);
        } catch (err) {
          logger.error(`Could not reach native wkwebview code and send message: ${message}`);
        }
      },
    };
  },

  notifyAppLoad() {
    logger.info('Sending Slave.Loaded');
    instance.sendMessage({
      action: 'Slave.Loaded',
    });
  },

  notifyGameEvent(event, game) {
    logger.info(`Notify game event: ${event}, ${game.url}`);
    instance.sendMessage({
      action: `Game.${capitalize(event)}`,
      data: {
        game: {
          id: game.id,
          productDisplayId: game.productDisplayId,
          code: game.code,
          demo: game.isDemoMode,
          title: game.title,
          providerName: game.provider?.name,
          url: game.url,
          position: game.position,
          isVaixEvent: game.isVaixEvent,
          swimLane: game.swimLane,
          page: constants.VAIX.HOMEPAGE,
          isLive: game.isLive,
        },
      },
    });
  },

  notifyGameImpression({ productName, gameIds, vaixSwimLane }) {
    logger.info(`Notify Analytics game impression event: ${productName}`);
    instance.sendMessage({
      action: 'Analytics.Send',
      data: {
        type: 'VaixImpressions',
        params: {
          category: productName,
        },
        content: [
          {
            id: vaixSwimLane,
            value: {
              gameIds,
              page: constants.VAIX.HOMEPAGE,
            },
          },
        ],
      },
    });
  },

  notifyRouteChange() {},

  notifyChangeTitle() {},

  notifyGroupsClickEvent(data) {
    logger.info('Sending Analytics.Send for groups/subgroups click');
    instance.sendMessage({
      action: 'Analytics.Send',
      data,
    });
  },

  requireUserLogin() {
    logger.info('Sending User.LoginRequired');
    instance.sendMessage({
      action: 'User.LoginRequired',
    });
  },

  requestGameOpening(game) {
    this.notifyGameEvent('open', game);
  },

  requestNavigationChange(url) {
    logger.info(`Sending Navigation.GoTo: ${url}`);
    instance.sendMessage({
      action: 'Navigation.GoTo',
      data: {
        url,
      },
    });
  },

  notifyJackpotWin(data) {
    logger.info('Sending JackpotWidget.Win');
    instance.sendMessage({
      action: 'JackpotWidget.Win',
      data,
    });
  },

  setListener() {
    window.onParentMessage = (message, payload) => {
      const initialData = payload?.data;
      const isLoadMessage = message === 'Slave.Load';
      logger.info(
        `Incoming message from native integrator: ${message}, ${prettyPrintJson(payload)}`,
      );

      // Handle missing user.logged flag
      if (isLoadMessage || message === 'User.AuthorizationChanged') {
        initialData.user = {
          ...initialData.user,
          logged: !!initialData.user?.uuid,
          profile: {
            token: initialData.user?.profileToken,
          },
        };
        initialData.token = initialData.user?.auth?.token;
      }

      if (isLoadMessage) {
        initializationResolve(initialData);
      } else {
        eventBus.publish(message, initialData);
      }
    };
  },
};
