import { each, isArray, isUndefined } from 'lodash-es';

export default {
  encode(params) {
    const queryParams = new URLSearchParams();

    each(params, (paramValue, key) => {
      if (isArray(paramValue)) {
        // multi-value query parameter
        each(paramValue, (paramMultiValue) => {
          queryParams.append(key, paramMultiValue);
        });
      } else if (!isUndefined(paramValue)) {
        queryParams.append(key, paramValue);
      }
    });

    return queryParams.toString();
  },
};
