import { createGtm } from '@gtm-support/vue-gtm';

export default (app, gtmCode) => {
  app.use(
    createGtm({
      id: gtmCode,
      debug: process.env.NODE_ENV === 'development',
      defer: true,
      compatibility: true,
    }),
  );
};
