import constants from '@/utility/constants';

export default {
  getGames: (state) => state.games,
  getFavoriteGames: (state) => state.favoriteGames,
  getProviders: (state) => state.providers,
  getPromotedGames: (state) => state.promotedGames,
  getRecentlyPlayedGames: (state) => state.recentlyPlayedGames,
  getRecommendedGames: (state) => state.recommendedGames,
  getTournamentGames: (state) => state.tournamentGames,
  getTop10Games: (state) => state.top10Games,
  getTrendingThisWeekGames: (state) => state.trendingThisWeekGames,
  getNewReleasesGames: (state) => state.newReleasesGames,
  getBecauseYouPlayedGames: (state) => state.becauseYouPlayedGames,
  getSupplierOfTheMonthGames: (state) => state.supplierOfTheMonthGames,
  getOurHottestTitlesGames: (state) => state.ourHottestTitlesGames,
  isAllGroupSelected: (state) => state.filters.group.id === constants.GROUP_IDS.ALL,

  getActiveGame: (state) => state.activeGame,
  getLastActiveGame: (state) => state.lastActiveGame,
  getLastPlayedGame: (state) => state.lastPlayedGame,
  getGroups: (state) => state.groups,

  isSearching: (state) => state.filters.search !== '',
  getFilters: (state) => state.filters,
  getSelectedGroup: (state) => state.filters.group,
  getSelectedSubgroup: (state) => state.filters.subgroup,
  getFilterByType: (state) => (type) => state.filters[type],
  getPagination: (state) => state.pagination,
  getGamesRequestParams: ({ pagination, filters }, { isVaixActive, getUser }) => ({
    page: pagination.page,
    size: pagination.size,
    filters,
    playerUuid: getUser.id,
    isVaixActive,
  }),

  getGameById: (state) => (id) => state.games.find((game) => game.id === id),
  getPromotedGameById: (state) => (id) => state.promotedGames.find((game) => game.id === id),
  getRecommendedGameById: (state) => (id) => state.recommendedGames.find((game) => game.id === id),
  getTournamentGameById: (state) => (id) => state.tournamentGames.find((game) => game.id === id),
  getTop10GameById: (state) => (id) => state.top10Games.find((game) => game.id === id),
  getTrendingThisWeekGameById: (state) => (id) =>
    state.trendingThisWeekGames.find((game) => game.id === id),
  getBecauseYouPlayedGameById: (state) => (id) =>
    state.becauseYouPlayedGames.find((game) => game.id === id),
  getRecentlyPlayedGameById: (state) => (id) =>
    state.recentlyPlayedGames.find((game) => game.id === id),
  getNewReleasesGameById: (state) => (id) => state.newReleasesGames.find((game) => game.id === id),
  getSupplierOfTheMonthGameById: (state) => (id) =>
    state.supplierOfTheMonthGames.find((game) => game.id === id),
  getOurHottestTitlesGameById: (state) => (id) =>
    state.ourHottestTitlesGames.find((game) => game.id === id),
  getFavoriteGamesIds: (state) => state.favoriteGames.map((game) => game.id),
  getFavoriteGameIndex: (state) => (id) => state.favoriteGames.findIndex((game) => game.id === id),
  getGroupById: (state) => (id) => state.groups.find((group) => group.id === id),
  getDefaultGroup: (state) => state.groups[0],
  getGroupByName: (state) => (name) => state.groups.find((group) => group.name === name),
};
