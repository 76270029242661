import types from './types';

export default {
  state: () => ({
    isGameModalVisible: false,
    isDescriptionModalVisible: false,
    isNotificationModalVisible: false,
    modalNotification: {
      message: 'Something went wrong!',
    },
  }),

  mutations: {
    [types.SHOW_DESCRIPTION_MODAL](state) {
      state.isDescriptionModalVisible = true;
    },
    [types.CLOSE_DESCRIPTION_MODAL](state) {
      state.isDescriptionModalVisible = false;
    },
    [types.SHOW_GAME_MODAL](state) {
      state.isGameModalVisible = true;
    },
    [types.CLOSE_GAME_MODAL](state) {
      state.isGameModalVisible = false;
    },
    [types.SHOW_NOTIFICATION_MODAL](state) {
      state.isNotificationModalVisible = true;
    },
    [types.SET_MODAL_NOTIFICATION](state, payload) {
      state.modalNotification = payload;
    },
    [types.CLOSE_NOTIFICATION_MODAL](state) {
      state.isNotificationModalVisible = false;
    },
  },

  getters: {
    isGameModalVisible: (state) => state.isGameModalVisible,
    isDescriptionModalVisible: (state) => state.isDescriptionModalVisible,
    isNotificationModalVisible: (state) => state.isNotificationModalVisible,
    getModalNotification: (state) => state.modalNotification,
  },

  actions: {
    openGameModal({ commit }) {
      commit(types.CLOSE_DESCRIPTION_MODAL);
      commit(types.SHOW_GAME_MODAL);
    },

    openDescriptionModal({ commit }) {
      commit(types.CLOSE_GAME_MODAL);
      commit(types.SHOW_DESCRIPTION_MODAL);
    },

    /**
     * @param
     * @param {{ message: string }} payload
     */
    openNotificationModal({ commit }, payload) {
      commit(types.SET_MODAL_NOTIFICATION, payload);
      commit(types.SHOW_NOTIFICATION_MODAL);
    },

    closeGameModal({ commit }) {
      commit(types.CLOSE_GAME_MODAL);
    },

    closeDescriptionModal({ commit }) {
      commit(types.CLOSE_DESCRIPTION_MODAL);
    },

    closeNotificationModal({ commit }) {
      commit(types.CLOSE_NOTIFICATION_MODAL);
    },
  },
};
