import constants from '@/utility/constants';

export default {
  hasBootstrapFailed: false,
  applicationSettings: {
    layout: constants.LAYOUT.DEFAULT,
    palette: constants.PALETTE.DEFAULT,
    theme: constants.THEME.DARK,
    font: constants.FONT.ROBOTO,
    isVaixActive: false,
    GTMCode: null,
    isDescriptionBoxActive: false,
    gameMobileHeader: null,
    liveCasinoGroupsActive: true,
    isSwipeDisabled: false,
    groupSubgroupIconsActive: false,
  },
  initialRoute: {
    navigationToInitialRouteFinished: false,
    name: null,
    params: null,
  },
  isScrollable: true,
  gamification: null,
  notification: {
    isVisible: false,
    variant: 'negative',
    text: 'Something went wrong!',
    timeout: null,
  },
  currency: {
    symbol: 'BAM',
    virtualSymbol: false,
  },
  visualViewport: {
    height: null,
    scrollTop: null,
  },
  tenant: {
    uuid: 'b99752b3-443c-4c80-b559-945a95c4b805',
    name: 'prvafirma',
    currency: 'BAM',
    virtualCurrency: 'BAM',
    country: 'BA',
  },
  referrerUrl: window.location.href,
  language: {
    iso1: 'en',
    iso2: 'eng',
  },
  iOSversion: {
    iOS12: false,
  },
  isLive: false,
  isMobile: {
    device: false,
  },
  marketingSlots: [],
  translations: {
    allLabel: 'All',
    backLabel: 'Back',
    closeAction: 'Close',
    reloadAction: 'Reload',
    demoLabel: 'Demo',
    okLabel: 'Ok',
    noLabel: 'No',
    continueLabel: 'Continue',
    realLabel: 'Real',
    fastDeposit: 'Fast Deposit',
    favoritesLabel: 'Favorites',
    newLabel: 'New',
    noFavoriteGamesMessage:
      'Add games to favorites by selecting the favorite option for desired game',
    noGamesMessage: 'No games found! Please select a different filter',
    noGameFoundForId: 'Selected game is not available',
    playAction: 'Play',
    playerNotVerified: 'Player not verified',
    popularLabel: 'Popular',
    searchPlaceholder: 'Find Games',
    searchSuggestionsClearSavedData: 'Clear saved data',
    recommendedLabel: 'Recommended for you',
    tournamentLabel: 'Tournament games',
    top10InSerbiaLabel: 'Top 10 in Serbia',
    trendingThisWeekLabel: 'Trending this week',
    gameOpeningError: 'Game loading interrupted! Please try again.',
    gameOpenLoginMessage: 'You have logged in. Do you wish to continue in Demo mode or Real?',
    gameOpenLogoutMessage: 'You have logged out and Demo mode is not supported for this game',
    demoGameOpenLogoutMessage: 'You have logged out. Do you wish to continue in Demo mode?',
  },
};
