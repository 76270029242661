<template>
  <div
    role="dialog"
    class="app-modal"
    @click="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  emits: ['close'],
};
</script>

<style
  lang="scss"
  scoped
>
.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-modal);
  background-image: linear-gradient(180deg, rgba(26, 28, 29, 0) 0%, #1a1c1d 100%);

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
