import { logger } from '@/utility';
import http from './http';

const getGamesRequestParams = ({ isNew, group, subgroup, search }) => {
  const params = {};

  // if searching, no need for other params
  if (search) {
    params.search = search;
    return params;
  }

  const isNotDefaultGroup = typeof group.id === 'number';

  if (isNew) params.new = isNew;

  if (subgroup?.id) {
    params.subgroupId = subgroup.id;
  } else if (group.id && isNotDefaultGroup) {
    params.groupId = group.id;
  }

  return params;
};

export default {
  async loadBootstrap() {
    const url = '/casino/bootstrap';
    const params = {
      include: ['popularGames', 'promotedGames'],
    };

    try {
      const { data } = await http.get(url, { params });

      return { data };
    } catch (e) {
      logger.error(`Load bootstrap failed: ${e.message}`);
      return {
        isError: true,
      };
    }
  },

  async loadGames({
    page = 1,
    size = 40,
    filters = {},
    playerUuid,
    isVaixActive,
    shouldClearCache,
  }) {
    let url = '/casino/web/games';
    if (isVaixActive) url += '/vaix';

    const headers = { 'X-Nsft-WebAPI-playerUuid': playerUuid };
    const params = getGamesRequestParams(filters);

    params.page = page;
    params.size = size;

    try {
      const { data } = await http.get(url, { params, headers, cache: !shouldClearCache });

      return { data };
    } catch (e) {
      logger.error(`Loading games failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadGame(gameId) {
    const url = `/casino/web/games?gameIds=${gameId}`;

    try {
      const { data } = await http.get(url);

      return { data: data.games[0] };
    } catch (e) {
      logger.error(`Loading game with id ${gameId} failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadFavoriteGames(userToken) {
    const url = '/casino/web/games/favorites';

    try {
      const { data } = await http.get(url, {
        headers: { Authorization: `Bearer ${userToken}` },
        cache: false,
      });

      return { data: data.games };
    } catch (e) {
      logger.error(`Loading favorite games failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },
};
