export default {
  LAYOUT: {
    DEFAULT: 'default',
    SECONDARY: 'secondary',
  },

  PALETTE: {
    DEFAULT: 'default',
    TOPBET: 'topbet',
    BALKANBET: 'balkanbet',
  },

  THEME: {
    LIGHT: 'light',
    DARK: 'dark',
  },

  FONT: {
    ROBOTO: 'Roboto:wght@400,700',
  },

  GROUP_IDS: {
    ALL: 'all',
    FAVORITES: 'fav',
    POPULAR: 'pop',
  },

  INTEGRATOR_PARAMS: {
    GAME: 'game',
  },

  VAIX: {
    SWIM_LANE_RECOMMENDED: 'recommended_for_you',
    SWIM_LANE_TOURNAMENT: 'promo',
    SWIM_LANE_TOP_N: 'top_n',
    SWIM_LANE_TRENDING_THIS_WEEK: 'trending',
    SWIM_LANE_NEW_RELEASES: 'new',
    SWIM_LANE_RECENTLY_PLAYED: 'recently_played',
    SWIM_LANE_BECAUSE_YOU_PLAYED: 'because_you_played',
    SWIM_LANE_SUPPLIER_OF_THE_MONTH: 'supplier_of_the_month',
    SWIM_LANE_OUR_HOTTEST_TITLES: 'hottest_games',
    HOMEPAGE: 'homepage',
  },

  ROUTES: {
    LOBBY: 'Lobby',
    LOBBY_GROUP: 'LobbyGroup',
    LOBBY_SUBGROUP: 'LobbySubgroup',
    LOBBY_GAME: 'LobbyGame',
  },

  BREAKPOINTS: {
    START: 1,
    SMALL: 768,
    MEDIUM: 1024,
    REGULAR: 1280,
    LARGE: 1440,
    XL: 1680,
    MAX: 1920,
  },

  CAROUSELS: {
    TOURNAMENT: 'tournament',
    TOP_N: 'top_n',
    TRENDING_THIS_WEEK: 'trending',
    RECOMMENDED_GAMES: 'recommended_for_you',
    NEW_RELEASES: 'new_releases',
    RECENTLY_PLAYED: 'recently_played',
    BECAUSE_YOU_PLAYED: 'because_you_played',
    SUPPLIER_OF_THE_MONTH: 'supplier_of_the_month',
    OUR_HOTTEST_TITLES: 'hottest_games',
  },
};
