import integrator from '@/services/integrator';

class User {
  constructor({ user }) {
    this.defaults = {
      name: 'guest',
      token: 'demo',
    };
    this.logged = user.logged;
    this.id = user.uuid || user.id || '';
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.balance = user.balance;
    this.nickname = user.nickname;
    this.name = user.name || this.defaults.name;
    this.token = user.auth?.token || this.defaults.token;
    this.profileToken = user.profile?.token || this.defaults.token; // legacy, should be removed
    this.timezoneOffset = user.profile?.timezoneOffset;
    this.oddsFormat = user.profile?.oddsDisplay;
    this.country = user.country;
  }

  static loginRequired() {
    integrator.requireUserLogin();
  }
}

export default User;
