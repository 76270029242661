import config from '../../../config';

const interceptor = (requestConfig) => {
  const transformedConfig = requestConfig;

  transformedConfig.headers['X-Nsft-SCD-Locale'] = config.language.iso1;
  transformedConfig.headers['X-Nsft-SCD-App-Name'] = `${config.tenant.uuid}_web_casino`;
  transformedConfig.headers['X-Nsft-SCD-Company-Id'] = config.tenant.uuid;
  transformedConfig.headers['X-Nsft-SCD-Company-Name'] = config.tenant.name;

  return transformedConfig;
};

export default interceptor;
