/* eslint-disable no-console */
export default {
  error: (msg) => {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') return;
    console.error(`7Casino ERROR: ${msg}`);
  },
  info: (msg) => {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') return;
    console.log(`7Casino INFO: ${msg}`);
  },
};
