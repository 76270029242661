import axios from 'axios';
import { setupCache, buildMemoryStorage, defaultKeyGenerator } from 'axios-cache-interceptor';
import { qpSerializer } from '@/utility';
import config from '../../config';
import interceptors from './interceptors';

const { api } = config;

const TTL_10_MINUTES = 10 * 60 * 1000;

const http = setupCache(
  axios.create({
    baseURL: api.casino,
    timeout: 10000,
    paramsSerializer: qpSerializer.encode,
  }),

  {
    ttl: TTL_10_MINUTES,
    methods: ['get'],
    storage: buildMemoryStorage(),
    generateKey: defaultKeyGenerator,
  },
);

http.interceptors.request.use(interceptors.requestInterceptor, (error) => Promise.reject(error));

export default http;
