import axios from 'axios';
import { logger } from '@/utility';
import config from '../../config';
import gravityInterceptor from './interceptors/gravity';

const { api, tenant } = config;

const http = axios.create({
  baseURL: api.gravity,
  timeout: 10000,
});

http.interceptors.request.use(gravityInterceptor, (error) => Promise.reject(error));

export default {
  async loadMarketingSlots() {
    const url = '/marketing/slots';

    try {
      const { data } = await http.get(url);

      return { data };
    } catch (e) {
      logger.error(`Loading marketing slots failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadApplicationSettings() {
    try {
      const APPLICATION_NAME = `${tenant.uuid}_web_casino`;

      const { data } = await http.get(`/apps/${APPLICATION_NAME}/settings/config`);

      return { data: data.appSetting.config };
    } catch (e) {
      return {
        isError: true,
      };
    }
  },

  async updateUserFavoriteGames({ userToken, favoriteGamesIds, favoriteGamesKey }) {
    const url = '/settings/users';
    const putData = {
      key: favoriteGamesKey,
      value: favoriteGamesIds,
      type: 'object',
    };

    try {
      const { data } = await http.put(url, putData, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      return { data };
    } catch (e) {
      logger.error(`Updating favorite games failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },
};
