export default {
  hasBootstrapFailed: (state) => state.hasBootstrapFailed,
  translations: (state) => state.translations,
  layout: (state) => state.applicationSettings.layout,
  theme: (state) => state.applicationSettings.theme,
  palette: (state) => state.applicationSettings.palette,

  getLanguage: (state) => state.language,
  getMarketingSlot: (state) => (name) => state.marketingSlots.find((slot) => slot.name === name),
  getTenant: (state) => state.tenant,
  getReferrerUrl: (state) => state.referrerUrl,
  getNotification: (state) => state.notification,
  getVisualViewport: (state) => state.visualViewport,
  getInitialNavigationData: (state) => state.initialRoute,
  getGameMobileHeader: (state) => state.applicationSettings.gameMobileHeader,
  getGamification: (state) => state.gamification,

  isIOS12: (state) => state.iOSversion.iOS12,
  isLive: (state) => state.isLive,
  isMobileDevice: (state) => state.isMobile.device,
  isNativeApp: (state) => state.isNativeApp,
  isMobileView: (state) => state.isMobile.device && !state.isNativeApp,
  isVaixActive: (state) => state.applicationSettings.isVaixActive,
  isDescriptionBoxActive: (state) => state.applicationSettings.isDescriptionBoxActive,
  isDebugErrorTrackerActiveForUser: (state, getters) =>
    state.applicationSettings.errorTracker?.debug?.active &&
    state.applicationSettings.errorTracker?.debug?.users?.includes(getters.getUser?.id),
  isScrollable: (state) => state.isScrollable,
  isGroupSubgroupIconsActive: (state) => state.applicationSettings.groupSubgroupIconsActive,
  isSwipeDisabled: (state) => state.applicationSettings.isSwipeDisabled,
  isTerminalChannelActive: (state) => state.channelType === 'terminalWrapper',
};
