import isMobile from 'ismobilejs';
import { isCrawler, localStorage } from '@/utility';
import iOSversion from '@/utility/iOSversion';
import { isEmpty } from 'lodash-es';

const {
  NODE_ENV,
  VUE_APP_API_BASEURL: casinoApiUrl,
  VUE_APP_API_BASEURL_CF: casinoApiUrlCF,
  VUE_APP_INTEGRATIONS_API: integrationsApiUrl,
  VUE_APP_GRAVITY_API_BASEURL: gravityApiUrl,
  VUE_APP_GRAVITY_API_BASEURL_CF: gravityApiUrlCF,
  VUE_APP_FRASIER_DOMAIN: frasierDomain,
  VUE_APP_FRASIER_API_BASEURL: apiFrasierUrl,
  VUE_APP_CF_ACTIVE,
  VUE_APP_ERROR_TRACKER_ACTIVE,
  VUE_APP_SENTRY_DSN: sentryDsn,
  VUE_APP_ALLOWED_INTEGRATOR_ORIGINS: integratorAllowedOrigins,
  VUE_APP_CASINO_INTEGRATION_ID: integrationCasinoId,
  VUE_APP_CASINO_LIVE_INTEGRATION_ID: integrationCasinoLiveId,
  VUE_APP_DEFAULT_INTEGRATION_TYPE: defaultIntegrationType,
  VUE_APP_DEFAULT_LANGUAGE_ISO1: languageIso1,
  VUE_APP_DEFAULT_LANGUAGE_ISO2: languageIso2,
  VUE_APP_DEFAULT_TENANT: defaultTenant, // Set in local env files if necessary
  VUE_APP_DEFAULT_TENANT_NAME: defaultTenantName, // Set in local env files if necessary
  VUE_APP_DEFAULT_CHANNEL_TYPE: defaultChannelType,
} = process.env;

const isErrorTrackerActive = VUE_APP_ERROR_TRACKER_ACTIVE === 'true';
const isCloudflareActive = VUE_APP_CF_ACTIVE === 'true' && NODE_ENV === 'production';

const parseFromPathName = (pathName) => {
  const parts = pathName.split('/');

  return {
    isLive: parts[2] === 'live',
  };
};

const parseFromQuerystring = (query) => {
  const parsed = {};
  query.substring(1).replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
    parsed[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
  });

  return parsed;
};

const checkPathConfigAndQueryConfig = ({ pathConfig, queryConfig }) => {
  const queryConfigFromLocalStorage = localStorage.getItem('queryConfig');

  if (isEmpty(queryConfig) && queryConfigFromLocalStorage) {
    return {
      pathConfig: localStorage.getItem('pathConfig'),
      queryConfig: queryConfigFromLocalStorage,
    };
  }

  localStorage.setItem('pathConfig', pathConfig);
  localStorage.setItem('queryConfig', queryConfig);
  return null;
};

const config = {
  api: {
    casino: casinoApiUrl,
    casinoCF: casinoApiUrlCF,
    integrations: integrationsApiUrl,
    gravity: gravityApiUrl,
    gravityCF: gravityApiUrlCF,
    frasier: apiFrasierUrl,
  },
  errorTracker: {
    active: isErrorTrackerActive,
    sentryDsn,
  },
  integration: {
    application: defaultChannelType,
    active: false,
    allowedOrigins: integratorAllowedOrigins,
    slaveId: integrationCasinoId,
    type: defaultIntegrationType,
    eventListeners: {
      scroll: true,
    },
    eventPropagation: {
      click: true,
    },
  },
  language: {
    iso1: languageIso1,
    iso2: languageIso2,
  },
  initialRouteParams: {},
  isLive: false,
  isMobile: {
    device: false,
  },
  tenant: {
    uuid: defaultTenant,
    name: defaultTenantName,
  },
  isIOS12: iOSversion === 12,
  isCloudflareActive,
  isNativeApp: false,
  useMemoryHistory: false,
  frasierDomain,
};

const handleInitialConfig = () => {
  config.isMobile.device = isMobile().any;

  let pathConfig = parseFromPathName(window.location.pathname);
  // Handle query parameter overrides
  let queryConfig = parseFromQuerystring(window.location.search);

  const updatedPathAndQueryConfig = checkPathConfigAndQueryConfig({
    pathConfig,
    queryConfig,
  });

  if (updatedPathAndQueryConfig) {
    pathConfig = updatedPathAndQueryConfig.pathConfig;
    queryConfig = updatedPathAndQueryConfig.queryConfig;
  }

  config.isLive = pathConfig.isLive;

  if (!queryConfig) return;

  const { integrationType, language, tenantUuid, tenantName, param1, param2, application } =
    queryConfig;

  // Add future qp config overrides here
  config.integration.active = !isCrawler() && !!integrationType && !process.env.VUE_APP_LOCAL_DEV;
  config.integration.type = integrationType;
  config.integration.application = application;

  config.isNativeApp = integrationType === 'webview' || integrationType === 'wkwebview';
  config.useMemoryHistory = !config.isNativeApp;

  if (config.isLive) config.integration.slaveId = integrationCasinoLiveId;

  if (language) config.language.iso1 = language;
  if (tenantUuid) config.tenant.uuid = tenantUuid;
  if (tenantName) config.tenant.name = tenantName;

  // Contract for handling routing params between app and 3rd party integrations
  if (param1) config.initialRouteParams.param1 = param1;
  if (param2) config.initialRouteParams.param2 = param2;
};

handleInitialConfig();

export default config;
