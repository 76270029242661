import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router';
import constants from '@/utility/constants';
import guards from './navigationGuards';
import config from '../../config';

const { ROUTES } = constants;

const lobbyPropsParser = ({ params }) => {
  let groupId = Number(params.groupId);
  // group id can also be a string for default groups
  if (!groupId) groupId = params.groupId;

  return {
    ...params,
    groupId,
    subgroupId: Number(params.subgroupId),
    gameId: Number(params.gameId),
  };
};

const router = createRouter({
  history: config.useMemoryHistory ? createMemoryHistory() : createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "appTemplate" */ '@/layouts/AppTemplate'),
      children: [
        {
          path: 'lobby/:type',
          name: ROUTES.LOBBY,
          component: () => import(/* webpackChunkName: "lobby" */ '@/pages/AppLobby'),
          props: lobbyPropsParser,
          children: [
            {
              path: ':groupId-:groupName',
              name: ROUTES.LOBBY_GROUP,
              component: () => import(/* webpackChunkName: "lobby" */ '@/pages/AppLobby'),
            },
            {
              path: ':groupId-:groupName/:subgroupId-:subgroupName',
              name: ROUTES.LOBBY_SUBGROUP,
              component: () => import(/* webpackChunkName: "lobby" */ '@/pages/AppLobby'),
            },
            {
              path: 'game/:gameId/:game',
              name: ROUTES.LOBBY_GAME,
              component: () => import(/* webpackChunkName: "lobby" */ '@/pages/AppLobby'),
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach(guards.handleInitialRoute);
router.afterEach(guards.notifyRouteChange);

export default router;
