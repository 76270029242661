import logger from './logger';

export default async (theme) => {
  try {
    await import(`@/styles/themes/${theme}/_index.scss`);
  } catch (err) {
    // fallback to default
    await import('@/styles/themes/default/_index.scss');
    logger.error(`No theme file found for theme: ${theme}`);
  }
};
