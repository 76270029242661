import { gravityApi, frasierApi } from '@/api';
import { logger } from '@/utility';
import types from '@/store/types';
import userTypes from '@/store/modules/user/types';
import gamesTypes from '@/store/modules/games/types';
import constants from '@/utility/constants';

import User from '@/models/User';
import MarketingSlot from '@/models/MarketingSlot/MarketingSlot';
import config from '../../config';

export default {
  setInitialAppData({ commit, state }, appData) {
    const data = appData || state;
    const user = new User(data);

    if (appData?.locale) commit(types.SET_LANGUAGE, appData.locale);
    if (appData?.settings) {
      if (Object.prototype.hasOwnProperty.call(appData.settings, 'visualViewport')) {
        commit(types.SET_VISUAL_VIEWPORT_HEIGHT, appData.settings.visualViewport);
      }
      if (Object.prototype.hasOwnProperty.call(appData.settings, 'scrollable')) {
        commit(types.SET_SCROLLABILITY, appData.settings.scrollable);
      }
      if (Object.prototype.hasOwnProperty.call(appData.settings, 'gamification')) {
        commit(types.SET_GAMIFICATION, appData.settings.gamification);
      }
    }
    commit(userTypes.SET_USER, user);
    commit(types.SET_TENANT, { tenant: data.tenant, currency: data.currency });
    commit(types.SET_REFERRER_URL, data.referrerUrl);
  },

  setInitialNavigationData({ commit }, payload) {
    commit(types.SET_INITIAL_NAVIGATION_DATA, payload);
  },

  setInitialNavigationFinished({ commit }) {
    commit(types.SET_INITIAL_NAVIGATION_FINISHED);
  },

  setVisualViewportScroll({ commit }, payload) {
    commit(types.SET_VISUAL_VIEWPORT_SCROLL, payload);
  },

  async loadInitialData({ dispatch, commit }) {
    commit(types.SET_CONFIG, config);

    await dispatch('loadTranslations');
    dispatch('loadMarketingSlots');
  },

  async loadApplicationSettings({ commit }) {
    const { data, isError } = await gravityApi.loadApplicationSettings();

    if (!data || isError) {
      logger.error('Failed to load theme');
      return;
    }

    const {
      theme = constants.THEME.DARK,
      palette = constants.PALETTE.DEFAULT,
      layout = constants.LAYOUT.DEFAULT,
      font = constants.FONT.ROBOTO,
      GTMCode = null,
      isVaixActive = false,
      isDescriptionBoxActive = false,
      gameMobileHeader = null,
      liveCasinoGroupsActive = true,
      groupSubgroupIconsActive = false,
      isSwipeDisabled = false,
      errorTracker = {
        debug: {
          active: false,
          users: [],
        },
      },
    } = data;

    commit(types.SET_APPLICATION_SETTINGS, {
      theme,
      palette,
      layout,
      font,
      GTMCode,
      isVaixActive,
      isDescriptionBoxActive,
      gameMobileHeader,
      liveCasinoGroupsActive,
      groupSubgroupIconsActive,
      errorTracker,
      isSwipeDisabled,
    });
  },

  async loadTranslations({ commit, getters, state }) {
    const { data, isError } = await frasierApi.loadTranslations(
      getters.getLanguage.iso1,
      getters.getTenant.uuid,
    );

    // Application will continue to work with default translations already on state
    if (!isError) {
      commit(types.SET_TRANSLATIONS, data);
    } else {
      logger.error('Failed to load translations');
    }

    commit(gamesTypes.UPDATE_DEFAULT_GROUPS, state.translations);
  },

  async loadMarketingSlots({ commit }) {
    const { data, isError } = await gravityApi.loadMarketingSlots();

    if (isError) {
      return;
    }

    const marketingSlots = data.marketingSlots.map((slot) => new MarketingSlot(slot));

    commit(types.SET_MARKETING_SLOTS, marketingSlots);
  },

  hideNotification({ commit }) {
    commit(types.HIDE_NOTIFICATION);
  },

  updateVisualViewportHeight({ commit }, payload) {
    commit(types.SET_VISUAL_VIEWPORT_HEIGHT, payload.visualViewport);
  },
};
