export default (font) => {
  if (!font) return;
  const googleFontsEndpoint = 'https://fonts.googleapis.com/css?family=';

  const head = document.querySelector('head');
  const link = document.createElement('link');

  link.rel = 'stylesheet';
  link.href = `${googleFontsEndpoint}${font}&display=swap`;

  head.appendChild(link);
};
