<template>
  <button
    class="btn"
    :class="classes"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return { 'btn-primary': this.primary };
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.btn {
  font-size: var(--fs-300);
  color: var(--accent);
  text-transform: uppercase;
  padding: var(--btn-padding);
}
</style>
