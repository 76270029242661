import { toNumber } from 'lodash-es';
import constants from './constants';

export default {
  getGroupParams(selectedGroup = {}, selectedSubgroup = {}) {
    const params = {};

    params.groupName = selectedGroup?.name;
    params.groupId = selectedGroup?.id || constants.GROUP_IDS.ALL;

    if (selectedSubgroup?.id) {
      params.subgroupName = selectedSubgroup?.name;
      params.subgroupId = selectedSubgroup?.id;
    }

    return params;
  },

  getFiltersFromParams({ group = {}, subgroup = {} }) {
    const groupFilterValue = {
      type: 'group',
      value: { id: group.id, name: group.name },
    };

    const subgroupFilterValue = {
      type: 'subgroup',
      value: { id: subgroup.id, name: subgroup.name },
    };

    return [groupFilterValue, subgroupFilterValue];
  },

  replaceSpacesWithDashes: (param = '') => param.split(' ').join('-'),

  getNameFromParam: (param = '') => param.split('-').slice(1).join(' '),

  getIdFromParam: (param = '') => {
    if (!param) return null;

    const [id] = param.split('-');

    if (toNumber(id)) {
      return toNumber(id);
    }

    return id;
  },
};
