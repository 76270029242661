import Game from '@/models/Game';
import { carouselsApi } from '@/api';
import gamesTypes from '@/store/modules/games/types';
import constants from '@/utility/constants';
import { MESSAGE_SEVERITY_LEVEL, trackMessageWithContext } from '@/utility';
import carouselsTypes from './types';
import getters from './getters';
import state from './state';

const appendOrderToGames = (games) => {
  games.forEach((game, i) => {
    // even items will have higher order and go in the second row https://phabricator.nsoft.ba/T126008
    // 10000 is an arbitrary high number
    const isEven = (i + 1) % 2 === 0;
    if (isEven) {
      game.setOrder(game.position + 10000);
    } else {
      game.setOrder(game.position);
    }
  });

  return games;
};

let carouselsAbortController = new AbortController();

export default {
  state,
  getters,
  mutations: {
    [carouselsTypes.SET_CAROUSELS](state, payload) {
      state.carousels = payload;
    },
    [carouselsTypes.ACTIVATE_CAROUSELS](state) {
      state.carousels = state.carousels.map((carousel) => {
        const activeCarousel = { ...carousel, isActive: true };

        return activeCarousel;
      });
    },
    [carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE](state, payload) {
      state.carousels = state.carousels.map((carousel) => {
        const newCarousel =
          carousel.type === payload.carouselType
            ? { ...carousel, isActive: payload.isCarouselActive }
            : carousel;
        return newCarousel;
      });
    },
  },

  actions: {
    async loadCarouselsData({ dispatch, getters }, { shouldClearCache, playerLoggedIn }) {
      carouselsAbortController.abort();
      carouselsAbortController = new AbortController();

      const params = { ...getters.getGamesRequestParams };
      const { data: carousels, isError } = await carouselsApi.loadCarousels(
        playerLoggedIn ? params.playerUuid : null,
        shouldClearCache,
        carouselsAbortController.signal,
      );

      if (!carousels || isError) return;

      dispatch('parseAndHandleCarousels', carousels);
    },
    parseAndHandleCarousels({ dispatch, commit, getters }, carousels) {
      const user = getters.getUser;
      let isBecauseYouPlayedActive;

      carousels.forEach((carousel) => {
        const swimLane = getters.getSwimLaneByCarouselType(carousel.type);
        const formattedGames = carousel.games?.map((game) => new Game({ ...game, swimLane }));

        if (
          Array.isArray(carousel.games) &&
          !carousel.games.length &&
          getters.isDebugErrorTrackerActiveForUser
        ) {
          trackMessageWithContext(
            `Empty games array for carousel ${carousel.type}`,
            MESSAGE_SEVERITY_LEVEL.debug,
            user,
          );
        }

        if (carousel.type === constants.CAROUSELS.TOURNAMENT) {
          commit(gamesTypes.SET_TOURNAMENT_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.TOP_N) {
          commit(gamesTypes.SET_TOP_10_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.TRENDING_THIS_WEEK) {
          commit(gamesTypes.SET_TRENDING_THIS_WEEK_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.RECOMMENDED_GAMES) {
          commit(gamesTypes.SET_RECOMMENDED_GAMES, appendOrderToGames(formattedGames));
        }
        if (carousel.type === constants.CAROUSELS.NEW_RELEASES) {
          commit(gamesTypes.SET_NEW_RELEASES_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.RECENTLY_PLAYED) {
          dispatch('loadRecentlyPlayedGames');
        }
        if (carousel.type === constants.CAROUSELS.BECAUSE_YOU_PLAYED) {
          isBecauseYouPlayedActive = true;
          dispatch('loadBecauseYouPlayedGames');
        }
        if (carousel.type === constants.CAROUSELS.SUPPLIER_OF_THE_MONTH) {
          commit(gamesTypes.SET_SUPPLIER_OF_THE_MONTH_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.OUR_HOTTEST_TITLES) {
          commit(gamesTypes.SET_OUR_HOTTEST_TITLES_GAMES, formattedGames);
        }
      });

      commit(carouselsTypes.SET_CAROUSELS, carousels);
      commit(carouselsTypes.ACTIVATE_CAROUSELS);
      if (isBecauseYouPlayedActive) {
        commit(carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE, {
          carouselType: constants.CAROUSELS.BECAUSE_YOU_PLAYED,
          isCarouselActive: false,
        });
      }
      commit(gamesTypes.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);
    },
    resetCarouselsData({ commit }) {
      commit(gamesTypes.SET_TOURNAMENT_GAMES, []);
      commit(gamesTypes.SET_TOP_10_GAMES, []);
      commit(gamesTypes.SET_TRENDING_THIS_WEEK_GAMES, []);
      commit(gamesTypes.SET_RECOMMENDED_GAMES, []);
      commit(gamesTypes.SET_RECENTLY_PLAYED_GAMES, []);
      commit(gamesTypes.SET_BECAUSE_YOU_PLAYED_GAMES, []);
      commit(gamesTypes.SET_SUPPLIER_OF_THE_MONTH_GAMES, []);
      commit(gamesTypes.SET_OUR_HOTTEST_TITLES_GAMES, []);
      commit(carouselsTypes.SET_CAROUSELS, []);
    },
  },
};
