import mitt from 'mitt';

const emitter = mitt();

export default {
  publish: (event, payload) => {
    emitter.emit(event, payload);
  },
  subscribe: (event, callback) => {
    emitter.on(event, (message) => callback(message));
  },
};
