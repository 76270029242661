// Directive function shorthand, since it has same behavior on mounted and updated
const TopPositionDirective = (
  el,
  { value: { isScrollable, visualViewportHeight, visualViewportScrollTop } },
) => {
  if (isScrollable) return;

  const topPosition = (visualViewportHeight - el.offsetHeight) / 2 + visualViewportScrollTop;
  const topPx = `${topPosition}px`;

  el.style.setProperty('top', topPx);
};

export default TopPositionDirective;
