<template>
  <div
    class="data-loader-spinner"
    role="alert"
  />
</template>

<script>
export default {
  name: 'AppLoader',
};
</script>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.data-loader-spinner {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 0.2rem solid var(--font-primary-1);
  border-left-color: var(--font-primary-3);
  animation: spin 1s infinite linear;
}
</style>
