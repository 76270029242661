import axios from 'axios';
import config from '../../config';

const http = axios.create({
  baseURL: config.api.frasier,
  timeout: 10000,
});

export default {
  async loadTranslations(language, tenant) {
    const mappedLanguage = language.split('-Latn')[0];
    const url = `/translations/${config.frasierDomain}/locales/${mappedLanguage}/tenants/${tenant}`;
    const params = {
      includeLocaleFallback: true,
    };

    try {
      const { data } = await http.get(url, { params });

      return { data };
    } catch (e) {
      return {
        isError: true,
      };
    }
  },
};
